import React, { useState } from "react";

// Custom hook for promocode functionality
const PromoCode = ({handlePromoCodeChange, promoCode, message, isValid, promotionValue, handleSubmitPromoCode, setPromtionValue, setIsValid}) => {




  return (
   <div className="flex flex-col items-center mt-4">
      <form onSubmit={handleSubmitPromoCode}>
        <div className="relative">
          <div className="flex items-center">
          
            <input
              className={`w-full px-4 py-2 mb-2 border rounded-md focus:outline-none focus:ring-2 ${isValid ? "focus:ring-green-500" : "focus:ring-red-500"} font-bold text-lg text-black`} 
              value={promoCode}
              onChange={handlePromoCodeChange}
              placeholder="Enter Promo Code"
              disabled={isValid }
            />
           
          </div>
          <button type="submit" className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-transparent border-0">
            {isValid && (
              <div className="flex-col justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 self-center ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.75} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="text-gray-500 text-sm">Validate</span>
              </div>
            ) || (
              <div className="flex-col justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 self-center ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.75} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="text-gray-500 text-sm">Validate</span>
              </div>
            )}
          </button>
        </div>
      </form>
      {message && <p className="text-center text-red-500">{message}</p>}
      {!isValid && <p className="text-center text-red-900">Invalid Promo Code!</p>}
      {isValid && <p className="text-center text-green-300">Valid Promo Code!</p>}
      {promotionValue && <p className="text-center text-green-300">{promotionValue}</p>}
    </div>

    /* CSS */

  );
};

export default PromoCode;
