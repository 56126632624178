import React, {useEffect} from 'react';
import StrainsCarousel from '../components/Carousel/StrainsCarousel';
import GerminationCarousel from '../components/Carousel/GerminationCarousel';
import Features from '../components/OurPromises/OurPromises';
import Header from '../components/Header/Header';
// import ComingSoonsStrainsCarousel from '../components/Carousel/ComingSoonStrainsCarousel';


const Home = () => {


	return (
		<>
			<Header />
			<StrainsCarousel />
			<Features /> 
			{/* <ComingSoonsStrainsCarousel /> */}
			<GerminationCarousel  />
		</>
	);
};

export default Home;
