import React, { useEffect, useState, useRef } from "react";
import { TiDelete } from "react-icons/ti";
import TextField from "@material-ui/core/TextField";
import Stack from "@mui/material/Stack";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";

const SearchBar = ({ handleStrainSearch, strainToSearch }) => {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    searchValue ? handleStrainSearch(searchValue) : handleStrainSearch("");
  }, [searchValue]);

  const handleClear = () => {
    setSearchValue("");
    handleStrainSearch("");
  };

  const PurpleBorderTextField = styled(TextField)`
    & label.Mui-focused {
      color: purple;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: purple;
      }
    }
  `;

  return (
    <Stack className="text-center mb-6">
      <PurpleBorderTextField
        inputRef={(input) =>
          input && searchValue.length > 0 ? input.focus() : undefined
        }
        onChange={(newValue) => {
          setSearchValue(newValue.target.value);
        }}
        className="w-full h-12  p-4"
        inputProps={{
          style: { color: "#F8F8FF", borderColor: "#F8F8FF"},
        }}
        variant="outlined"
        value={strainToSearch}
        placeholder="Search by strain title..."
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: searchValue ? "visible" : "hidden",
                color: "red",
              }}
              onClick={handleClear}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </Stack>
  );
};
export default SearchBar;
