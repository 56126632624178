import React from 'react';
import ReactFontSizeByTextLength from 'react-font-size-by-text-length';

import { Container, Section } from '../../globalStyles';
import {
	OurPromisesText,
	OurPromisesTitle,
	OurPromisesWrapper,
	OurPromisesColumn,
	OurPromisesImageWrapper,
	OurPromisesName,
	OurPromisesTextWrapper,
} from './OurPromisesStyles';
import { OurPromisesData } from '../../data/OurPromisesData';

const OurPromises = () => {
	const initial = {
		y: 40,
		opacity: 0,
	};
	const animate = {
		y: 0,
		opacity: 1,
	};

	return (
		<Section smPadding="50px 10px" position="relative" inverse id="shop">
			<Container>
				<OurPromisesTextWrapper>
					<OurPromisesTitle>Our Promise to You</OurPromisesTitle>
				</OurPromisesTextWrapper>
				<OurPromisesWrapper>
					{OurPromisesData.map((el, index) => (
						<OurPromisesColumn
							initial={initial}
							animate={animate}
							transition={{ duration: 0.5 + index * 0.1 }}
							key={index}
						>
							<OurPromisesImageWrapper className={el.imgClass}>
								{el.icon}
							</OurPromisesImageWrapper>
							<OurPromisesName>{el.name}</OurPromisesName>
							<OurPromisesText>{el.description}</OurPromisesText>
						</OurPromisesColumn>
					))}
				</OurPromisesWrapper>
			</Container>
		</Section>
	);
};

export default OurPromises;
