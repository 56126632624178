import styled from 'styled-components';
import { Button } from '../../globalStyles';

export const HeaderSection = styled.section`
	height: 100vh;
	background-position: center;
	background-size: cover;
	padding-top: clamp(70px, 25vh, 220px);
	box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
	margin-top: 4.5vh;
	margin-bottom: 250px;
`;

export const HeaderImage = styled.img`
	object-fit: cover;
	width: 100%;
	height: 150%;
	background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
	top: 0;
	position: absolute;
	z-index: -1;
	opacity: .9;
`;


export const HeaderText = styled.h3`
	margin-bottom: 10px;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	letter-spacing: .75px;
	text-shadow: 2px 2px #000;
	color:  ${({ fcolor }) => (fcolor ? fcolor : '#F8F8FF')};
	font-weight: 600;

`;

export const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	gap: 0.5rem;
`;

export const HeaderButton = styled(Button)`
	color: purple;

	&:before {
		background: #fff;
		height: 500%;
	}

	&:hover:before {
		height: 0%;
	}

	&:hover {
		color: white;
		border-color: purple;
		
	}
`;
