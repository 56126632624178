import React from 'react';
import Form from '../components/Form/Form';

function ContactPage() {
	return (
		<>
			<Form />
		</>
	);
}

export default ContactPage;
