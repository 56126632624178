import styled from 'styled-components';

export const CheckoutItemContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;
  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
`;

export const ImageContainer = styled.div`
  width: 75px;
  heigth: 75px;
  padding-right: 15px;
  border-radius: 3px;
  img {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 500px) {
    display: none;
   
   }
`;

export const TextContainer = styled.span`
  width: 33.33%;
  @media screen and (max-width: 800px) {
    width: %;
  }
`;

export const SmallText = styled.span`
  font-size: .75rem;
  margin: ${({margin})=>(margin?margin:'auto')}
  position: absolute;
  top: 300000px;
  left: 1700px;
`;

export const QuantityContainer = styled(TextContainer)`
  display: flex;
  span {
    margin: 0 10px;
  }
  div {
    cursor: pointer;
  }
  @media screen and (max-width: 500px) {
    width: 50%;
    margin: 10px
  }
  
`;

QuantityContainer.displayName = 'QuantityContainer';

export const RemoveButtonContainer = styled.div`
  padding-left: 12px;
  cursor: pointer;
`;

RemoveButtonContainer.displayName = 'RemoveButtonContainer';