import styled from 'styled-components';
import { Row } from '../../globalStyles';
import Slider from 'react-slick';

export const GerminationCarouselImage = styled.img`
	width: 100%;
	height: 300px;
	border-radius: 10px 10px 0 0;
	object-fit: cover;
	vertical-align: middle;
`;

export const GerminationImageWrapper = styled.div`
	width: 200px;
	display: flex !important;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	outline: none;
	height: auto;

	@media screen and (min-width: 440px) {
		border: 1px solid #bebebe;
	}
`;

export const GerminationButtonContainer = styled(Row)`
margin-top: 1rem;
margin-right: 25vw;
margin-left: 25vw;
margin-bottom: 10px;

	& svg {
		cursor: pointer;
	}

	& svg:hover {
		opacity: 0.3;
		transition: opacity 0.2s ease-in;
	}
	@media screen and (max-width: 960px) {
		margin: 0 auto;
	}
`;

export const GerminationReviewSlider = styled(Slider)`
	width: 100%;

	.slick-track {
		display: flex;
		padding: 30px;
		gap: 3rem;
	}
	.slick-slide {
		display: flex;
		justify-content: center;
		margin-bottom: 1;
		outline: none;
	}

	.slick-list {
		overflow: hidden;
	}
`;

export const GerminationCardButton = styled.button`
	background-color: #428f4e;
	font-size: 1.3rem;
	padding: 5px 10px;
	color: #fff;
	cursor: pointer;
	width: 100%;
	font-weight: 600;
	margin: auto 0 0 0;
	border: none;
	border-radius: 0 0 10px 10px;

	&:hover {
		transition: background-color 0.2s ease-in;
		color: purple;
	}
`;
