import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { germinationData, sliderSettings } from '../../data/GerminationData';
import { Row, Heading, Section, TextWrapper } from '../../globalStyles';
import {
	GerminationButtonContainer,
	GerminationReviewSlider,
	GerminationImageWrapper,
	GerminationCarouselImage,
	GerminationCardButton,
} from './GerminationCarouselStyles';

const GerminationCarousel = () => {
	const [sliderRef, setSliderRef] = useState(null);
	const history = useHistory();

	const handleClick = () => {
		history.push('/contact')
	};


	return (
		<Section id="how-to" margin="auto" maxWidth="1280px" padding="50px 70px" inverse>
			<Row justify="center" margin="2rem" wrap="wrap">
				<Heading  inverse>
					How Chubby does it.
					<br />
					Our 5-Step Germination Guide
				</Heading>
				<GerminationButtonContainer>
					<IconContext.Provider value={{ size: '2.75rem', color: '#428f4e' }}>
						<FaArrowCircleLeft onClick={sliderRef?.slickPrev} />
						<FaArrowCircleRight onClick={sliderRef?.slickNext} />
					</IconContext.Provider>
				</GerminationButtonContainer>
			</Row>

			<GerminationReviewSlider {...sliderSettings} ref={setSliderRef}>
				{germinationData.map((el, index) => (
					<GerminationImageWrapper key={index}>
						<GerminationCarouselImage src={el.image} />
						<TextWrapper size="1.1rem" margin="30px" weight="bold">
							{el.title}
						</TextWrapper>
						<TextWrapper size="0.9rem" margin="0.7rem" color="#4f4f4f">
							{el.description}
						</TextWrapper>
						<GerminationCardButton onClick={handleClick}>Contact Us</GerminationCardButton>
					</GerminationImageWrapper>
				))}
			</GerminationReviewSlider>
		</Section>
	);
};

export default GerminationCarousel;
