import styled from "styled-components";
export const CheckoutPageContainer = styled.div`
  padding: 160px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #428f4e;
  color: #fff;
  @media only screen and (max-width: 500px) {
    font-size: 14px;
    letter-spacing: 0.51px;
  }
`;

export const CheckoutHeaderContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid darkgrey;
  color: #fff;
`;

export const CheckoutHeaderBlock = styled.div`
  text-transform: capitalize;
  width: 100%;
  margin-left: 80px;
  &:last-child {
    width: 7.5%;
  }
  @media only screen and (max-width: 500px) {
    margin-left: 10px;
    font-size: 12px;
    letter-spacing: 0.25px;
  }
`;

export const TotalContainer = styled.div`
  margin-left: auto;
  margin-top: 20px;
  font-size: 1.75rem;
  font-weight: 500;
  color: #fff;
  letter-spacing: 2px;
  @media only screen and (max-width: 500px) {
    font-size: 20px;
    letter-spacing: 0.51px;
  }
`;
export const SubTotalContainer = styled.div`
  margin-left: auto;
  margin-top: 20px;
  font-size: 1.75rem;
  font-weight: 500;
  color: #fff;
  letter-spacing: 2px;
  @media only screen and (max-width: 500px) {
    font-size: 20px;
    letter-spacing: 0.51px;
  }
`;
export const SubtotalSpan = styled.span`
    font-size: 55%;
    font-weight: lighter;
    padding; 0;
    display: block;
 
`;

export const PayNowBTNcontainer = styled.div`
  text-align: center;
  font-size: 3.75rem;
  margin-top: 20px;
  margin-left: 85%;
  min-width: 8%;
  margin-right: 7%;
`;

export const CheckoutHeading = styled.h2`
margin-left: 37.5vw;
width: 15vw;
margin-right: 37.5vw;
color: #fff;
@media only screen and (max-width: 500px) {
margin-left: -5px;
margin-bottom: 20px;
letter-spacing: 2px;
font-size: 16px;

`;
