export const germinationData = [
	{
		title: 'Step 1: Prep humidity dome',
		description: 'You ONLY need purified or distilled water and a humidity dome.  Fill bottom of dome (bottom tray part) with distilled or purified water, only enough to reach the top of the canal. ',
		image: './assets/step-1-germ.jpeg'
	},
	{
		title: 'Step 2: Place dome on heat mat.',
		description: 'You need a seedling heating mat for this step. Place your humidity dome bottom onto the heat mat.',
		image: './assets/step-2-germ.jpeg'
	},
	{
		title: 'Step 3: Place your humidity dome bottom onto the heat mat.',
		description: 'You need a root riot cube and one of your Chubby\'s seeds. place your chubby seed in your cube. The seed should be flat side up, pointy side facing down.. The seed should go 1/4 inch into the cube. ',
		image: './assets/step-3-germ.jpeg'
	},
	{
		title: 'Step 4: Place cube with seed into dome tray.',
		description: 'place your root riot cube that contains your chubby seed into the humidity dome. put the dome lid on, make sure the top vents are closed for 100% humidity. you can place a couple drops of water onto the seed itself when its inside the cube, this is optional.  Light is not required for popping your seed. keep the lid on for 36 hours- 4 days (depending on when your seed pops, it should be between 36 hours and 4 days tops.)',
		image: './assets/step-4-germ.jpeg'
	},
	{
		title: 'Step 5: Transplant.',
		description: `what we use: -happy frog soil,-5 gallon squat pot,-mars hydro TS600 LED light,-purified/distilled water. What we do:  
		make a little hole in your squat pot that is filled with happy frog
		moisten the hole 
		place cube in hole 
		lightly pack around cube
		light schedule: 18/6 up to 24/0
		water till soil is moist, light spray daily/ water typically 2-3 days (whenever soil is dry to the touch)
		This cutie was transplanted 36 hours after the initial start of step 1!`,
		image: './assets/step-5-germ.jpeg'
	},
];

export const sliderSettings = {
	arrows: false,
	slidesToShow: 3,
	focusOnselect: false,
	accessability: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
