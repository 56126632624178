export const data = [
	{
		to: '/contact',
		text: "Let's Connect",
	},
	{
		to: '/marketplace',
		text: 'Shop',
	},
	{
		to: '/',
		text: 'How To',
		id: 'how-to',
	},


];
