import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { CiWarning } from "react-icons/ci";

const Modal = ({ setIsOpen }) => {
  const [isOldEnoughChecked, setIsOldEnoughChecked] = useState(false);
  const [isTooYoungChecked, setIsTooYoungChecked] = useState(false);
  useEffect((isOldEnoughChecked, isTooYoungChecked, setIsOpen) => {
    if (isTooYoungChecked) {
      return;
    }
    if (isOldEnoughChecked) {
      setIsOpen(false);
    }
  });

  return (
    <>
      <div className={styles.darkBG} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>Welcome to Chubby's</h5>
          </div>
          <div className={styles.modalContent}>
            The content of this website provides information about souvenir
            seeds and is not suitable for anyone under the age of 21.
          </div>
          <CiWarning
            size={100}
            style={{
              marginTop: 20,
              marginLeft: "30%",
              marginRight: "30%",
              width: "40%",
            }}
            color="indianred"
          />
          <div> 
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <div className="checkbox-wrapper">
                <input
                  disabled={isOldEnoughChecked ? true : false}
                  onChange={() => setIsTooYoungChecked((prev) => !prev)}
                  checked={isTooYoungChecked}
                  type="checkbox"
                />
                <label>
                  <span>I'm almost 21</span>
                </label>
                              <br />
                              <br/>

                <input
                  disabled={isTooYoungChecked ? true : false}
                  onChange={() => {
                    setIsOldEnoughChecked((prev) => !prev);
                  }}
                  onClick={() =>
                    isOldEnoughChecked ? setIsOpen(true) : setIsOpen(false)
                  }
                  checked={isOldEnoughChecked}
                  type="checkbox"
                />
                <label>
                  <span>You bet I'm 21-years-old or older</span>
                </label>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;