import React from 'react';

import { BsFillShieldLockFill } from 'react-icons/bs';
import { BiSupport, BiDollar } from 'react-icons/bi';
import { GiFarmer,GiReceiveMoney } from 'react-icons/gi';
const iconStyle = (Icon) => <Icon size="3rem" color="rgb(66,143,78)" />;

export const OurPromisesData = [
	{
		name: 'Money Back Guarantee',
		description: 'Sh*t happens! in the rare event your seeds get damaged in the mail, or seem to not be sprouting as they should, your purchase will be fully exchanged or refunded.',
		icon: iconStyle(GiReceiveMoney),
		imgClass: 'one',
	},
	{
		name: '100% Organically Grown',
		description: 'Our seeds were bred with love, using nothing but 100% organic materials for soil and nutrients while cultivating these strains',
		icon: iconStyle(GiFarmer),
		imgClass: 'two',
	},
	{
		name: 'Excellent Grower Support',
		description:'Contact us, we are here to help answer any questions or concerns with your chubby\'s seedling and even your transplant.',
		icon: iconStyle(BiSupport),
		imgClass: 'four',
	},
	{
		name: 'Stable Genetics',
		description:'We thoroughly test our genetics with different training techniques and climate contributors, promising to NEVER rush a strain to market. We picky here at Chubby\'s, only releasing our favorite, most potent, stabalized strains. Don\'t be afraid to ask what works and what does not, as we\'ve tested these ladies thoroughly, and we know what\'ll work -- and what wont!',
		icon: iconStyle(BsFillShieldLockFill),
		imgClass: 'six',
	},
	{
		name: 'Great Value',
		description: 'We offer top-shelf genetics at a value/cost ratio that can\'t be matched!',
		icon: iconStyle(BiDollar),
		imgClass: 'five',
	},

	{
		name: 'Tireless Search for the Best',
		description:'We will continue to rotate genetics in and out of our lineup to not only keep a fresh variety of strains for you to enjoy, but to retire out the lowest performing strains regularly ensuring we are only increasing the value for you as a grower come Harvest Day!',
		icon: iconStyle(BsFillShieldLockFill),
		imgClass: 'six',
	},
];
