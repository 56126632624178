import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectCartHidden } from "../../redux/cart/cart.selectors";
import { createStructuredSelector } from "reselect";
import { Button, Heading } from "../../globalStyles";
import { IconContext } from "react-icons/lib";
import {
  MarketplaceSection,
  MarketplaceWrapper,
  MarketplaceContainer,
  MarketplaceCardInfo,
  MarketplaceCardTitle,
  MarketplaceCardCost,
  MarketplaceCardStrainClass,
  MarketplaceCardFeatures,
  MarketplaceCardText,
  MarketplaceCardFeature,
  MarketplaceCard,
  MarketplaceImage,
} from "./MarketplaceStyles";
import CartDropdown from "../CartDropdown/CartDropdown";
import CartIcon from "../CartIcon/CartIcon";
import { TitlePriceContainer } from "./MarketplaceStyles";
import { marketplaceData } from "../../data/MarketplaceData";
import { addItem } from "../../redux/cart/cart.actions";
import {
  selectCartItems,
  selectCartTotal,
} from "../../redux/cart/cart.selectors";
import DropdownFilter from "../DropdownFilter/dropdownFilter";
import SearchBar from "../SearchBar/searchBar";

function Marketplace({ addItem, cartItems }) {
  const [selectedCartItem, setSelectedCartItem] = useState(null);
  const [filteredStrains, setFilteredStrains] = useState(marketplaceData);
  const [filterVal, setFilterVal] = useState("All Strains");
  const [strainToSearch, setStrainToSearch] = useState("");

  const filterStrains = (filterVal) => {
    switch (filterVal) {
      case "Regular Photoperiod":
        { setFilteredStrains(
          marketplaceData.filter(
            (strain) => strain.strainClass === "Regular Photo"
          )
        
        );
        strainToSearch.length > 0 ? setStrainToSearch("") : null
      }

        break;
      case "Feminized Photoperiod":
        {
          strainToSearch.length > 0 ? setStrainToSearch("") : null
          setFilteredStrains(
          marketplaceData.filter(
            (strain) => strain.strainClass === "Feminized Photoperiod"
          )
        );
        
      }

        break;
      case "Feminized Autoflower":
        {
          strainToSearch.length > 0 ? setStrainToSearch("") : null
          setFilteredStrains(
          marketplaceData.filter(
            (strain) => strain.strainClass === "Feminized Autoflower"
          )
        );
       
      }
        break;
      case "All Strains":
        {
          strainToSearch.length > 0 ? setStrainToSearch("") : null
          setFilteredStrains(marketplaceData);
        }
        break;
      default:
        {
          strainToSearch.length > 0 ? setStrainToSearch("") : null
          setFilteredStrains(marketplaceData);
        }
        break;
    }
  };
  const handleFilterValue = (Event) => {
    setFilterVal(Event.target.value);
  };
  const handleStrainSearch = async (strainToSearch) => {
    setStrainToSearch(strainToSearch);
    if(!strainToSearch){return}
    let filteredResults = [];
    function searchForStrain() {
      filteredResults = marketplaceData.filter(
        (strain) => strain.title.toLowerCase() === strainToSearch.toLowerCase()
      );
    }
     searchForStrain();
     !filteredResults[0] ? null : setFilteredStrains(filteredResults)
    // if (!filteredResults[0]) {
    //   return;
    // } else {
    //   setFilteredStrains(filteredResults);
    // }
  };

  useEffect(() => {
    filterStrains(filterVal);
  }, [filterVal]);
  useEffect(() => {
    if (strainToSearch == "") {
      setFilteredStrains(marketplaceData);
    }
  }, [strainToSearch]);
  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: "1rem" }}>
      <MarketplaceSection className="pt-36 w-screen" id="Marketplace">
        <MarketplaceWrapper>
          <Heading className="text-4xl mx-5 my-5">
            Chubby's Strain Marketplace
          </Heading>
          <section className="py-4">
            <SearchBar
              strainToSearch={strainToSearch}
              handleStrainSearch={handleStrainSearch}
             
            />
            <DropdownFilter
              filterValue={filterVal}
              handleFilterValue={handleFilterValue}
             
            />

            {filteredStrains.length <= 1 ? (
              <p className="text-slate-200 text-xs mt-auto text-center">
                {filteredStrains.length} strain available
              </p>
            ) : (
              <p className="text-slate-200 text-xs mt-auto text-center">
                {filteredStrains.length} strains available
              </p>
            )}
          </section>
          <MarketplaceContainer className="w-10/12">
            {filteredStrains.map((strain, index) => (
              <MarketplaceCard key={index}>
                <MarketplaceCardInfo>
                  <TitlePriceContainer>
                    <MarketplaceCardTitle className="text-gray-800	text-center text-5xl my-2 max-w-96">
                      {strain.title}{" "}
                    </MarketplaceCardTitle>
                    <MarketplaceCardText className="text-gray-500	text-center text-sm my-2">
                      {strain.cross}
                    </MarketplaceCardText>
                    <MarketplaceCardStrainClass className="text-gray-700 text-center text-base my-0.5">
                      {strain.strainClass}
                    </MarketplaceCardStrainClass>
                    <MarketplaceCardCost className="text-base text-center text-gray-700 my-2">
                      {strain.displayPrice}
                    </MarketplaceCardCost>
                  </TitlePriceContainer>
                  <MarketplaceImage
                    className="object-fill w-full sm:w-4/object-cover object-center w-full rounded-lg shadow-xl h-96 shadow-blue-gray-900/50"
                    src={strain.image}
                  />
                  <MarketplaceCardFeatures>
                    {strain.features.map((feature, index) => (
                      <MarketplaceCardFeature className="text-gray-700" key={index}>
                        {feature}
                      </MarketplaceCardFeature>
                    ))}
                  </MarketplaceCardFeatures>
                  {strain.id === selectedCartItem && <CartDropdown />}

                  <CartIcon
                    disabled={false}
                    margin="10px 40%"
                    onClick={() =>
                      selectedCartItem === strain.id
                        ? setSelectedCartItem(null)
                        : setSelectedCartItem(strain.id)
                    }
                  />
                  {cartItems.length >= 1 && (
                    <span
                      style={{
                        marginLeft: "12.5%",
                        marginTop: "5px",
                        marginBottom: "10px",
                        fontSize: "16px",
                        color: "purple",
                      }}
                    >
                      Click shopping bag icon to view your cart and checkout
                    </span>
                  )}
                  <Button onClick={() => addItem(strain)}>Add to Cart</Button>
                </MarketplaceCardInfo>
              </MarketplaceCard>
            ))}
          </MarketplaceContainer>
        </MarketplaceWrapper>
      </MarketplaceSection>
    </IconContext.Provider>
  );
}

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  hidden: selectCartHidden,
});

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
