import styled from 'styled-components';
import CustomButton from '../CustomButton/CustomButton';
export const CartDropdownContainer = styled.div`

  width: 80%;
  margin-horizontal: 10%;
  height: 35vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1.33px solid #800080;
  border-radius: 3px;
  background-color: white;
  z-index: 5;
`;
export const CartDropdownContainerNav = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1.33px solid #800080;
  border-radius: 3px;
  background-color: white;
  top: 110px;
  right: 7vw;
  z-index: 5;
`;


export const CartDropdownButton = styled(CustomButton)`
  margin-top: 15px;
  
`;

export const EmptyMessageContainer = styled.span`
  font-size: 18px;
  margin: 50px auto;
`;

export const CartItemsContainer = styled.div`
  height: 32vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;