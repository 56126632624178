import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row, TextWrapper } from "../../globalStyles";
import Slider from "react-slick";

export const CarouselImage = styled.img`
  width: 100%;

  border-radius: 9px 9px 0 0;
  object-fit: cover;
  vertical-align: middle;
  filter: grayscale(25.75%);
  transition: all 0.6685s ease;

  &:hover {
    border-radius: 9px 9px 0 0;
    filter: grayscale(0%);
    transform: scale(1.05);
    transition: all 0.6685s ease;
  }
`;

export const ImageWrapper = styled.div`
  width: 95%;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  outline: none;

  @media screen and (min-width: 440px) {
    border: 1px solid #bebebe;
  }
`;

export const ButtonContainer = styled(Row)`

  & svg {
    align-self: center;
    cursor: pointer;
  }

  & svg:hover {
    opacity: 0.3;
    transition: opacity 0.2s ease-in;
  }
  @media screen and (max-width: 960px) {
    margin: 0 auto;
  }
`;

export const ReviewSlider = styled(Slider)`
  width: 100%;

  .slick-track {
    display: flex;
    padding: 30px;
    gap: 3rem;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 1;
    outline: none;
  }

  .slick-list {
    overflow: hidden;
  }
`;

export const CardButton = styled.button`
  background-color: #428f4e;
  font-size: 1.3rem;
  padding: 5px 10px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  margin: auto 0 0 0;
  border: none;
  border-radius: 0 0 10px 10px;

  &:hover {
    transition: background-color 0.2s ease-in;
    color: purple;
  }
`;
export const CardButtonLink = styled(Link)`
  background-color: #428f4e;
  font-size: 1.3rem;
  padding: 5px 10px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  margin: auto 0 0 0;
  border: none;
  border-radius: 0 0 10px 10px;

  &:hover {
    transition: background-color 0.2s ease-in;
    color: purple;
  }
`;

export const StrainTextWrapper = styled(TextWrapper)`
  margin-top: 6.5px;
  margin-bottom: 6.5px;
`;
