import React, { useState, useEffect } from "react";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCartItemsCount } from "./redux/cart/cart.selectors";
import Navbar from "./components/Navbar/Navbar";
//Pages
import Home from "./pages/Home";
import LetsConnect from "./pages/ContactPage";
import Marketplace from "./pages/MarketplacePage";
import Footer from "./components/Footer/Footer";
import CheckoutPage from "./components/Checkout/Checkout";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import Modal from "./components/Modal/Modal";
import { marketplaceData } from "./data/MarketplaceData";
import { strains } from "./data/StrainData";
const App = ({ cartItems }) => {
  const [isOpen, setIsOpen] = useState(false);
//   const refreshCacheAndReload = async() => {
// 		// delete browser cache and hard reload
// 		 window.location.reload(false);
// 	}
// 	useEffect(()=>{
// 	 refreshCacheAndReload()
//  }, [])
  


  return (

          <Router>
            <GlobalStyle />
            <Navbar />
            <Switch>
              <ErrorBoundary>
                {isOpen && <Modal setIsOpen={setIsOpen} />}

                <Route path="/" exact component={Home} />
                <Route path="/contact" exact component={LetsConnect} />
                <Route path="/marketplace" exact component={Marketplace} />
                <Route path="/checkout" exact component={CheckoutPage} />
              </ErrorBoundary>
            </Switch>
            <Footer />
          </Router>
   
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItemsCount,
});

export default connect(mapStateToProps)(App);


