import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import makeAnimated from 'react-select/animated';
function Promo5packModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  openModal,
  subtitle,
  onPromoPackSelection,
  promoSelections
}) {
  const [firstPromoPackSelection, setfirstPromoPackSelection] = useState(null);
  const [secondPromoPackSelection, setsecondPromoPackSelection] =
    useState(null);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "55%",
      height: "55%",
      backgroundColor: "#F8F8FF",
      boxShadow: "0 0 60px 10px rgba(0, 0, 0, 0.9)",
      position: "absolute",

      /* spacing as needed */
      padding: "20px 50px 20px 20px",

      /* let it scroll */
      overflow: "auto",
    },
  };

  const options = [
    { value: "Shield maiden Surprise_firstSelection", label: "Shield maiden Surprise" },
    { value: "OG Kush Surprise_firstSelection", label: "OG Kush Surprise" },
    { value: "Tropical Skunk Surprise_firstSelection", label: "Tropical Skunk Surprise" },
    { value: "Blue Dream Auto_firstSelection", label: "Blue Dream Auto" },
    { value: "Wedding cheesecake Auto_firstSelection", label: "Wedding cheesecake Auto" },
    { value: "Shield maiden Surprise_secondSelection", label: "Shield maiden Surprise" },
    { value: "OG Kush Surprise_secondSelection", label: "OG Kush Surprise" },
    { value: "Tropical Skunk Surprise_secondSelection", label: "Tropical Skunk Surprise" },
    { value: "Blue Dream Auto_secondSelection", label: "Blue Dream Auto" },
    { value: "Wedding cheesecake Auto_secondSelection", label: "Wedding cheesecake Auto" },
  ];


  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "rgb(0,0,0)";
    subtitle.style.margin = "20px 25%";
    subtitle.style.width = "50%";
    subtitle.style.fontWeight = "400";
  }
  const animatedComponents = makeAnimated();

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Chubby's Promo Pack Selection Modal"
      appElement={document.getElementById('root')}
      
    
      
    >
      <div style={styles.modalHeaderDiv}>
        <h1 style={styles.modalHeaderStyle}>THANK YOU!!!</h1>
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
          You Qualified for Chubby's Free 5-pack of Surprise or Auto Seeds!
        </h2>
      </div>
      
      <form>
        <div style={styles.divStyle}>
          Please select Your Two Free Packs!
        </div>

        <Select
          closeMenuOnSelect={false}
          isMulti
          hideSelectedOptions={false}
          components={animatedComponents}
          onChange={newValue => onPromoPackSelection(newValue)}
          options={options}
          onMenuClose={promoSelections.length==2?closeModal():null}
        />

        {/* <div style={styles.divStyle}>
          Please select Your Second of Two Free Packs!
        </div>
        <Select onChange={newValue => onSecondPromoPackSelection(newValue)} options={options} /> */}
      </form>
    </Modal>
  );
}
const styles = {
  modalHeaderStyle: {
    fontSize: 18,
    margin: "20px 25%",
    color: "rgb(102, 0, 44)",
    width: "50%",
    fontWeight: "900",
  },
  divStyle: {
    margin: "20px 25%",
    fontSize: 20,
    fontWeight: "500",
  },
  selectStyle: {
    margin: "20px 20%",
    maxWidth: 200,
  },
  modalHeaderDiv: {},
};

export default Promo5packModal;
