const CartActionTypes = {
  TOGGLE_CART_HIDDEN: "TOGGLE_CART_HIDDEN",
  ADD_ITEM: "ADD_ITEM",
  CLEAR_ITEM: "CLEAR_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  CLEAR_CART: "CLEAR_CART",
  CALCULATE_FREEBIES: "CALCULATE_FREEBIES",
};

export default CartActionTypes;
