import React from "react";
import {
  FooterLinkItems,
  FooterLink,
  FooterLogo,
  FooterRights,
  FooterSocialIcon,
  FooterWrapper,
  FooterAddress,
  FooterGrid,
} from "./FooterStyles";
import { footerData, footerSocialData } from "../../data/FooterData";
import { Row, Section } from "../../globalStyles";

function Footer() {
  return (
    <Section padding="1.75rem 0 1rem 0">
      <Row align="center" margin="auto 55vw 1rem 45vw" gap="1rem">
        {footerSocialData.map((social, index) => (
          <FooterSocialIcon
            key={index}
            href={social.linkTo}
            target="_blank"
            className="ml-4"
            aria-label={social.name}
          >
            {social.icon}
          </FooterSocialIcon>
        ))}
      </Row>
      <FooterWrapper>
        <FooterGrid justify="space-between">
          <FooterLogo to="/">
            {/* <SocialIcon src="./assets/logo.png" /> */}
            Chubby's Seed Supply Co.
          </FooterLogo>

          {footerData.map((footerItem, index) => (
            <FooterLinkItems key={index}>
              {footerItem.links.map((link, linkIndex) => (
                <FooterLink key={linkIndex} to={link.elmID}>
                  {link.uiTitle}
                </FooterLink>
              ))}
            </FooterLinkItems>
          ))}
          <FooterAddress>
            2831 Roberts avenue <br />
            Bronx, NY 10461
          </FooterAddress>
        </FooterGrid>
        <FooterRights>Chubbys Seed Supply Co. 2022</FooterRights>
      </FooterWrapper>
    </Section>
  );
}

export default Footer;
