import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Facebook',
		icon: iconStyle(FaFacebook),
		linkTo: "https://www.facebook.com/ChubbysSeedSupply/"
	},
	{
		name: 'Instagram',
		icon: iconStyle(FaInstagram),
		linkTo: "https://www.instagram.com/chubbysseedsupply/"
	}
];

export const footerData = [
	{
		title: 'Main',
		links: [{uiTitle:'How To', elmID: '/'},{uiTitle:'Let\'s Connect', elmID: '/contact'}, {uiTitle:'Shop', elmID: '/marketplace'}],
	}
];
