import styled from "styled-components";


export const FreebieSelectorContainer = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
color: #000;
padding: 20px;
font-size: 18px;
`;