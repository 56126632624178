import styled from 'styled-components';

import { ReactComponent as ShoppingIconSVG } from '../../assets/shopping-bag-svg.svg';

export const CartContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({margin})=>(margin?margin:'1px')}
`;

export const ShoppingIcon = styled(ShoppingIconSVG)`
  width: 55px;
  height: 55px;
  font-color: #fff;
`;


export const ItemCountContainer = styled.span`
  position: absolute;
  font-size: 10px;
  font-weight: lighter;
  bottom: 20px;
  left: 20px;
  color: ${({color}) => (color?color:'#fff')};
`;
export const SmallText = styled.span`
  position: absolute;
  font-size: 10px;
  font-weight: lighter;
  bottom: 10px;
  left: 12px;
  color: ${({color}) => (color?color:'#fff')};
`;

