import styled from 'styled-components';

export const CartItemsContainer = styled.div`

  width: 100%;
  display: flex;
  height: 85px;
  margin-bottom: 1.33%;
  border-radius: .333vh;
  border-radius: 3px;
`;

export const CartItemImage = styled.img`   
width: 33.33%;
border-radius: .333vh;
`;
 


  export const CartItemsDetailsContainer = styled.div`
  
  width: 66.67%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 10px;
  font-size: .8rem;`;
   
