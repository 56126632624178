import React from 'react';

import {CartItemImage, CartItemsContainer, CartItemsDetailsContainer} from './CartItem.styles';
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const CartItem = ({ item: { image, displayPrice, price, title, quantity, strainClass } }) => (
  <CartItemsContainer className='cart-item'>
    <CartItemImage src={image}
    loading="lazy"
    alt='item' 
    decoding="async"
    />
    <CartItemsDetailsContainer className='item-details'>
      <span className='name'>{title}</span>
      <span className='name'>{strainClass}</span>
      <span className='price'>
        {quantity} x {formatter.format(price)}
      </span>
    </CartItemsDetailsContainer>
  </CartItemsContainer>
);

export default React.memo(CartItem);

