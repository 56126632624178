import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { toggleCartHidden } from "../../redux/cart/cart.actions";
import { selectCartItemsCount } from "../../redux/cart/cart.selectors";

import {
  CartContainer,
  ShoppingIcon,
  ItemCountContainer,
  SmallText,
} from "./CartIcon.styles";

const CartIcon = ({ toggleCartHidden, itemCount, margin, onClick }) => (
  <CartContainer onClick={onClick} margin={margin}>
    <ShoppingIcon />
    <ItemCountContainer color={"#000"}>{itemCount}</ItemCountContainer>
    <SmallText color={"#000"}>
      <br />
      seeds
    </SmallText>
  </CartContainer>
);

const mapDispatchToProps = (dispatch) => ({
  toggleCartHidden: () => dispatch(toggleCartHidden()),
});

const mapStateToProps = createStructuredSelector({
  itemCount: selectCartItemsCount,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon);
