import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Container, MainHeading } from "../../globalStyles";
import {
  HeaderImage,
  HeaderSection,
  HeaderText,
  ButtonWrapper,
  HeaderButton,
} from "./HeaderStyles";
let headerstyleShadow;
const Header = () => {
  const [show, setShow] = useState(false);

  let history = useHistory();
  let location = useLocation();

  const scrollTo = (id) => {
    const element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const closeMobileMenu = (to, id) => {
    if (id && location.pathname === "/") {
      scrollTo(id);
    }

    history.push(to);
    setShow(!show);
  };
//   useEffect(()=>{
// 	 headerstyleShadow = {{textShadowColor: 'rgba(0, 0, 0, 0.8)'}, 
// 	 {textShadowOffset: { width: 0, height: 0 }},
// 	 {textShadowRadius: 10}},
//   }, [])
  return (
    <HeaderSection>
      <HeaderImage src="./assets/main_image/main-image-1.jpeg" />
      <Container>
        <MainHeading marginB={"20px"} marginT={"28.55vh"} fcolor={"#F8F8FF"}>
          Chubby's Seed Supply
        </MainHeading>
        <HeaderText fcolor={"white"}>
          100% Organic Feminized Seeds
          <br />
          We Ship Worldwide!
        </HeaderText>
        <br />
        <HeaderText>
  
          Orders over 100USD will receive two FREE 5 packs with choices of Shield
          Maiden Surprise, OG Kush Surprise, Tropical Skunk Surprise, Blue Dream
          auto, or Wedding Cheesecake auto.
        
        </HeaderText>
        <ButtonWrapper>
          <Link to="contact">
            <HeaderButton>Let's Connect</HeaderButton>
          </Link>
          <HeaderButton
            onClick={() => closeMobileMenu("/", "strains-carousel")}
          >
            Shop Our Strains
          </HeaderButton>
        </ButtonWrapper>
      </Container>
    </HeaderSection>
  );
};



export default Header;
