import * as React from "react";

const DropdownFilter = ({ handleFilterValue, filterValue }) => {
  return (
    <div className="my-4 mb-2 text-center">
      <select
        className="text-sm size-2 touch-auto text-zinc-500 w-24"
        name="filter"
        value={filterValue}
        onChange={handleFilterValue}
      >
        <option value="All Strains">All Strains</option>
        <option value="Feminized Photoperiod">Feminized Photoperiod</option>
        <option value="Feminized Autoflower">Feminized Autoflower</option>
        <option value="Regular Photoperiod">Regular Photoperiod</option>
      </select>
    </div>
  );
};

export default DropdownFilter;
