import styled from 'styled-components';
import CustomButton from '../CustomButton/CustomButton';
import { motion } from 'framer-motion';
import { Container } from '../../globalStyles';

export const MarketplaceSection = styled.div`
	
	background: #428f4e;
	@media screen and (max-width: 760px) {
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100vw;
		
	}
`;

export const MarketplaceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: 760px) {
		
		width: 100vw;
		
	}

`;

export const MarketplaceContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-flow: wrap;


	@media screen and (max-width: 760px) {
		
		width: 100vw;
		
	}



`;
export const TitlePriceContainer = styled(Container)`
margin: auto;
font-size: 16px;

@media screen and (max-width: 768px) {
	width: 90%;
	margin: 20px auto;
	

	&:hover {
		transform: none;
	}
}



`;
export const MarketplaceImage = styled.img`


`;


export const MarketplaceSuccessMSG = styled(motion.div)`
filter: grayscale(50%);
font-size: 18px;
color: #428f4e	padding: 5px;
	text-align: center;
	margin-top: 1rem;
`;
export const MarketplaceCard = styled.div`

	margin: auto;
	align-content: stretch;
	text-align: stretch;

	&:hover {
		transform: scale(1.01);
		transition: all 0.3s ease-out;
	}
	@media screen and (max-width: 768px) {
		min-width: 85vw;
		margin-left: auto;
		

		&:hover {
			transform: none;
		}
	}
	
`;

export const MarketplaceCardInfo = styled.div`
	background: #F0FFF2;
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
	width: 33vw;
	text-decoration: none;
	border-radius: 4px;
	height: 100%;

	display: flex;
	flex-direction: column;
	padding: 20px;
	color: #000;
	margin: 20px;
	> button {
		margin-top: auto;
		background: #428f4e;

		&:hover {
			color: purple;
			background: #428f4e;
			transition: background 0.3s ease;
		}
	}

	@media screen and (max-width: 768px) {
		width: 90%;

		&:hover {
			transform: none;
		}
	}
`;
export const AddButton = styled(CustomButton)`
  width: 78%;
  opacity: 0.7;
  position: absolute;
  top: 255px;
  border-radius: 3px;
  display: none;
`;

export const MarketplaceCardTitle = styled.h4`
	
	
	
	
	
	
`;
export const MarketplaceCardStrainClass = styled.p`

`;

export const MarketplaceCardCost = styled.h4`

`;

export const MarketplaceCardText = styled.p`
	
`;

export const MarketplaceCardFeatures = styled.ul`
	margin: 20px 25%;
	list-style: none;
	display: flex;
	flex-direction: column;
	color: #a9b3c1;
	width: 50%;
`;

export const MarketplaceCardFeature = styled.li`
	margin-bottom: 1rem;
	display: flex;
	font-size: 0.94rem;

	&:before {
		content:'🌱';
		margin-right: 0.4rem;
	}
`;
