import React, { useState } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { IconContext } from "react-icons";
import { strains, sliderSettings } from "../../data/StrainData";
import { Row, Heading, Section, TextWrapper } from "../../globalStyles";
import {
  ButtonContainer,
  ReviewSlider,
  ImageWrapper,
  CarouselImage,
  CardButton,
  CardButtonLink,
} from "./StrainsCarouselStyles";

const StrainsCarousel = () => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <Section
      margin="auto 50px auto auto"
      maxWidth="1280px"
      padding="50px 70px"
      inverse
    >
      <Row justify="space-between" margin="2rem" wrap="wrap">
        <Heading className="text-2xl text-center mb-8 text-extrabold" inverse id="strains-carousel">
          Chubby's Stock
        </Heading>
        <ButtonContainer className="flex items-center justify-center  w-full rounded-full py-4 pl-8 mt-8 mb-6" >
          <IconContext.Provider  value={{ size: "3rem", color: "#428f4e" }}>
            <FaArrowCircleLeft className="mx-2" onClick={sliderRef?.slickPrev} />
            <FaArrowCircleRight className="mx-2" onClick={sliderRef?.slickNext} />
          </IconContext.Provider>
        </ButtonContainer>
      </Row>

      <ReviewSlider {...sliderSettings} ref={setSliderRef}>
        {strains.map((el, index) => (
          <ImageWrapper key={index}>
            <CarouselImage src={el.images[0]} />
            <TextWrapper className="text-3xl mt-3">
              {el.title}
            </TextWrapper>
            <TextWrapper className="text-xs font-extrabold">
              {el.title2}
            </TextWrapper>
            {el.cross != null && (
              <TextWrapper className="text-lg text-center text-slate-400 mx-2.5 my-1.5">
                {el.cross}
              </TextWrapper>
            )}
            
            {el.description && (
              <TextWrapper className="text-center text-slate-700 text-base my-2 mx-10">
                {el.description}
              </TextWrapper>
            )}

              <TextWrapper className="text-center text-slate-700 text-base my-2 mx-10">
              {el.thcLevels}
            </TextWrapper>
            <TextWrapper className="text-center text-slate-700 text-base  mx-10 my-2">
              {el.sativaVSindica}
            </TextWrapper>
            {el.yield != null &&
              <TextWrapper className="text-center text-slate-700 text-base my-2  mx-10">
              {el.yield}
              </TextWrapper>
            }
              <TextWrapper className="text-center text-slate-700 text-base my-2  mx-10">
              {el.flowerTime}
            </TextWrapper>
            <CardButtonLink to="marketplace">
              <CardButton>Buy Seeds Now</CardButton>
            </CardButtonLink>
          </ImageWrapper>
        ))}
      </ReviewSlider>
    </Section>
  );
};

export default StrainsCarousel;
