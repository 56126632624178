import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  FormColumn,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormLabel,
  FormInputRow,
  FormMessage,
  FormButton,
  FormTitle,
} from "./FormStyles";
import { Container } from "../../globalStyles";
import validateForm from "./validateForm";
import { API_BASE_URL } from "../../config";

const Form = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [seedlingInterest, setSeedlingInterest] = useState(false);
  const [howWeGrowInterest, setHowWeGrowInterest] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const resultError = validateForm({
      firstName,
      lastName,
      message,
      seedlingInterest,
      howWeGrowInterest,
      email,
    });

    if (resultError !== null) {
      setError(resultError);
      return;
    }
    if (resultError === null) {
      let emailMessageObject = {
        // customerMSG = data.get("message")
        // customerEmail = data.get("email")
        // helpNeededWith = data.get("helpNeeded")
        message,
        email,
        seedlingHelp: seedlingInterest
          ? "Needs help with seedling"
          : "No help needed here",
        growingHelp: howWeGrowInterest
          ? "Needs help with how to grow"
          : "No help needed here",
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: "POST",
        mode: "cors",
      };
      options.body = JSON.stringify(emailMessageObject);

      fetch(`${API_BASE_URL}/chubbyscontactmanager`, options)
        .then((response) => response.body)
        .then((body) => {
          const reader = body.getReader();

          return new ReadableStream({
            start(controller) {
              return pump();

              function pump() {
                return reader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }

                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            },
          });
        })

        .then((stream) => new Response(stream))
        .then((response) => {
          if (response.status === 200) {
            history.push("/");
          }
        });
    }
    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
    setHowWeGrowInterest(false);
    setSeedlingInterest(false);
    setError({});
    setSuccess(
      "Your Message was submitted to us successfully!  Please check the email inbox you provided for further conversation and instructions :) Have a great day!"
    );
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const formData = [
    {
      label: "First Name",
      value: firstName,
      id: "firstName",
      onChange: (e) => {
        setFirstName(e.target.value), setError({});
      },
      type: "text",
    },
    {
      label: "Last Name",
      value: lastName,
      id: "lastName",
      onChange: (e) => {
        setLastName(e.target.value), setError({});
      },
      type: "text",
    },

    {
      label: "Email",
      value: email,
      id: "email",
      onChange: (e) => {
        setEmail(e.target.value), setError({});
      },
      type: "email",
    },
    {
      label: "Message for Us",
      value: message,
      id: "message",
      onChange: (e) => {
        setMessage(e.target.value), setError({});
      },
      type: "text",
    },
  ];

  console.log("error", error);
  return (
    <FormSection id="contact">
      <Container>
        <FormRow>
          <FormColumn small>
            <FormTitle>We Can't Wait to Hear From You!</FormTitle>
            <FormWrapper onSubmit={handleSubmit}>
              {formData.map((el, index) => (
                <FormInputRow key={index}>
                  <FormLabel>{el.label}</FormLabel>
                  <FormInput
                    type={el.type}
                    placeholder={`Please enter your ${el.label.toLocaleLowerCase()}`}
                    value={el.value}
                    onChange={el.onChange}
                  />
                  {error[el.id] && (
                    <FormMessage
                      variants={messageVariants}
                      className="text-left"
                      initial="hidden"
                      animate="animate"
                      error
                    >
                      {error[el.id]}
                    </FormMessage>
                  )}
                </FormInputRow>
              ))}

              <div className="flex flex-row my-2 items-center">
                <input
                  type="checkbox"
                  value={howWeGrowInterest}
                  className="p-2 cursor-pointer w-[20px] h-[20px]"
                  onChange={() => setHowWeGrowInterest(!howWeGrowInterest)}
                />
                <FormLabel
                  className="ml-2"
                  onClick={() => setHowWeGrowInterest(!howWeGrowInterest)}
                >
                  I'd like to know how Chubby's grows this strain
                </FormLabel>
              </div>

              <div
                className="flex flex-row my-2 items-center"
                onClick={() => setSeedlingInterest(!seedlingInterest)}
              >
                <input
                  type="checkbox"
                  value={seedlingInterest}
                  className="p-2 cursor-pointer w-[20px] h-[20px]"
                  onChange={() => setSeedlingInterest(!seedlingInterest)}
                />
                <FormLabel className="ml-2">
                  I'd like help from Chubby's with my seedling
                </FormLabel>
              </div>

              {error.checkbox && (
                <FormMessage
                  variants={messageVariants}
                  className="text-left"
                  initial="hidden"
                  animate="animate"
                  error
                >
                  {error.checkbox}
                </FormMessage>
              )}

              {/* <FormInputRow>
                <FormLabel>Last Name</FormLabel>
                <FormInput
                  type="text"
                  placeholder={`Please enter your lastname`}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormInputRow>

              <FormInputRow>
                <FormLabel>First Name</FormLabel>
                <FormInput
                  type="text"
                  placeholder={`Please enter your firstname`}
                  value={firstName}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormInputRow> */}
              {/* {error && (
                <FormMessage
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                  error
                >
                  {error}
                </FormMessage>
              )} */}
              {success && (
                <FormMessage
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {success}
                </FormMessage>
              )}

              <FormButton type="submit">Send us your message</FormButton>
            </FormWrapper>
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default Form;
