import React, { useEffect, useState } from "react";
import { marketplaceData } from "../../data/MarketplaceData";
import { FreebieSelectorContainer } from "./FreebieSelector.styles";

const FreebieSelector = ()=>{
    return (
        <>
{marketplaceData.map((strain, index)=>{
   

   <p key={strain.title+strain.id} style={{fontSize:"44px", color:"#fff"}}>{strain.title} </p>
   

})}
</>

    )}
export default FreebieSelector;