export default function validateForm({
  firstName,
  lastName,
  message,
  email,
  howWeGrowInterest,
  seedlingInterest,
}) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!firstName.trim()) {
    return { firstName: "First Name is required" };
  } else if (!/^[A-Za-z]+/.test(firstName.trim().toLocalLowerCase)) {
    return {
      firstName:
        "Your first name must have at least one letter, and letters only :)",
    };
  }
  if (!lastName.trim()) {
    return { lastName: "Last Name is required" };
  } else if (!/^[A-Za-z]+/.test(lastName.trim().toLocalLowerCase)) {
    return {
      lastName:
        "Your last name must have at least one letter, and letters only :)",
    };
  }

  if (!email) {
    return { email: "Email required" };
  } else if (regex.test(email.toLocalLowerCase)) {
    return { email: "Email address is invalid" };
  }

  if (!message.trim()) {
    return {
      message:
        "We Can't help you if you don't provide us with a message body! :) ",
    };
  } else if (!/[\w[\]`!.:;'-]*/.test(message.trim())) {
    return {
      message:
        "Please only use letters (upper or lower) and numbers (0-9) in the message body :) ",
    };
  }
  if (!howWeGrowInterest && !seedlingInterest) {
    return { checkbox: "Please select one of your interests :)" };
  }
  return null;
}
